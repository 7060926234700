import { named, withDependencies } from '@wix/thunderbolt-ioc'
import type { MenuContainerComponent, MenuToggleComponent, MenuToggleData, MenuToggleWillMountFactory } from './types'
import { name } from './symbols'
import { ComponentsStoreSymbol } from 'feature-components'
import { PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'

const menuToggleWillMount: MenuToggleWillMountFactory = ({ componentsConfig }, componentsStore) => {
	const getMenuContainer = (toggleButton: MenuToggleComponent): MenuContainerComponent | null => {
		const { menuContainerId } = componentsConfig[toggleButton.id] as MenuToggleData
		const comp = componentsStore.get<MenuContainerComponent>(menuContainerId)
		return comp?.componentType === 'MenuContainer' ? comp : null
	}

	return {
		componentTypes: ['MenuToggle', 'VectorImage'],
		componentWillMount: (toggleButton) => {
			if (!componentsConfig[toggleButton.id]) {
				return
			}
			toggleButton.onKeyDown((keyboardEvent) => {
				if (keyboardEvent.key === 'Enter' || keyboardEvent.key === ' ') {
					const menuContainer = getMenuContainer(toggleButton)
					if (menuContainer) {
						menuContainer.toggle(false)
					}
				}
			})
			toggleButton.onClick(() => getMenuContainer(toggleButton)?.toggle(false))
			if (toggleButton.componentType === 'MenuToggle') {
				return getMenuContainer(toggleButton)?.onToggle((isOpen) => toggleButton.updateProps({ isOpen }))
			}
		},
	}
}

export const MenuToggleWillMount = withDependencies(
	[named(PageFeatureConfigSymbol, name), ComponentsStoreSymbol],
	menuToggleWillMount
)
