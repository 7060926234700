import { withDependencies, optional, named } from '@wix/thunderbolt-ioc'
import {
	ViewModeSym,
	BrowserWindowSymbol,
	PageFeatureConfigSymbol,
	ViewMode,
	BrowserWindow,
} from '@wix/thunderbolt-symbols'
import type { AnimationData, MenuContainerData, MenuContainerDriver, IMenuContainerHooks } from './types'
import { MenuContainerHooksSymbol, name, MenuContainerFocusApiSymbol } from './symbols'

import { Animations, IAnimations } from 'feature-animations'
import { ISiteScrollBlocker, SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import { disableCyclicTabbing, enableCyclicTabbing, isSSR } from '@wix/thunderbolt-commons'
import { AnimationHandler } from './AnimationHandler'
import _ from 'lodash'
import { ComponentDriverFactory } from 'feature-components'
import { MenuContainerComponent, MenuContainerPageConfig, IMenuContainerFocusApi } from './types'

const menuContainerDriverFactory = (
	{ componentsConfig }: MenuContainerPageConfig,
	viewMode: ViewMode,
	window: BrowserWindow,
	siteScrollBlocker?: ISiteScrollBlocker,
	animations?: IAnimations,
	menuContainerHooks?: IMenuContainerHooks,
	menuContainerFocusApi?: IMenuContainerFocusApi
): ComponentDriverFactory<MenuContainerComponent> => {
	const animationHandler = animations && AnimationHandler(animations)

	return {
		componentType: 'MenuContainer',
		getComponentDriver: (viewerComponent) => {
			const onToggleCallbacks: Record<string, (isOpen: boolean) => void> = {}
			const menuIds = Object.keys(componentsConfig)

			const animateIn = (animation: AnimationData) =>
				new Promise<void>((resolve) => {
					animationHandler!.animate(
						viewerComponent.id,
						{
							inBehavior: animation,
							outBehavior: { name: 'outBehavior' },
						},
						true,
						() => {
							resolve()
						}
					)
				})

			const toggle = (immediate: boolean = false) =>
				new Promise<void>((resolve) => {
					const isOpen = !viewerComponent.getProps().isOpen
					const shouldAnimate = !immediate && animationHandler
					const shouldUpdateVisibility = isOpen || !shouldAnimate
					const isMobile = viewMode === 'mobile'
					if (isOpen) {
						menuContainerHooks?.registerToInnerScroll(viewerComponent.id)
						if (menuContainerFocusApi?.isMenuContainerToggleOnPage()) {
							menuContainerFocusApi?.enableCyclingInMenuContainer()
						} else {
							enableCyclicTabbing(menuIds.map((id) => `#${id}`))
						}
					} else {
						menuContainerHooks?.unregisterInnerScroll(viewerComponent.id)
						if (menuContainerFocusApi?.isMenuContainerToggleOnPage()) {
							menuContainerFocusApi?.disableCyclingInMenuContainer()
						} else {
							disableCyclicTabbing()
						}
					}

					siteScrollBlocker && siteScrollBlocker.setSiteScrollingBlocked(isOpen, viewerComponent.id)
					if (shouldAnimate) {
						menuContainerHooks?.onAnimationStart()
						animationHandler!.animate(
							viewerComponent.id,
							(componentsConfig[viewerComponent.id] as MenuContainerData).animations,
							isOpen,
							(reversed: boolean) => {
								menuContainerHooks?.onAnimationEnd()
								const isClosed = reversed ? isOpen : !isOpen
								if (isClosed) {
									viewerComponent.updateProps({
										isVisible: false,
									})
								}
								resolve()
							}
						)
					}

					Object.values(onToggleCallbacks).forEach((c) => c(isOpen))
					viewerComponent.updateProps({
						isOpen,
						...(shouldUpdateVisibility && { isVisible: isOpen }),
					})
					if (isOpen) {
						viewerComponent.updateStyle({
							'--menu-height':
								!isSSR(window) && isMobile ? window.getComputedStyle(document.body).height : '100vh',
						})
					}
					if (!shouldAnimate) {
						resolve()
					}
				})

			const toggleIfNeeded = async (openMenu: boolean, immediate?: boolean) => {
				const isMenuOpen = viewerComponent.getProps().isOpen

				if (openMenu !== isMenuOpen) {
					await toggle(immediate)
				}
			}

			const api: MenuContainerDriver = {
				open: (immediate) => toggleIfNeeded(true, immediate),
				toggle,
				onToggle: (callback) => {
					const callbackId = _.uniqueId('callback')
					onToggleCallbacks[callbackId] = callback
					return () => delete onToggleCallbacks[callbackId]
				},
				close: (immediate) => toggleIfNeeded(false, immediate),
				animateIn,
				unblockScroll: () =>
					siteScrollBlocker && siteScrollBlocker.setSiteScrollingBlocked(false, viewerComponent.id),
			}

			return api as any
		},
	}
}

export const MenuContainerDriverFactory = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		ViewModeSym,
		BrowserWindowSymbol,
		optional(SiteScrollBlockerSymbol),
		optional(Animations),
		optional(MenuContainerHooksSymbol),
		optional(MenuContainerFocusApiSymbol),
	],
	menuContainerDriverFactory
)
