import { withDependencies } from '@wix/thunderbolt-ioc'
import { BrowserWindow, BrowserWindowSymbol } from '@wix/thunderbolt-symbols'
import { IMenuContainerFocusApi } from './types'

const getMenuContainerToggleElement = (window: BrowserWindow) => {
	if (!process.env.browser) {
		return false
	}
	return window!.document.getElementById('MENU_AS_CONTAINER_TOGGLE')
}

const enableCyclicTabbing = (window: BrowserWindow) => {
	if (!process.env.browser) {
		return
	}
	const siteHeader = window!.document.getElementById('SITE_HEADER')
	const toggleBtnElement = getMenuContainerToggleElement(window)
	if (siteHeader && toggleBtnElement) {
		siteHeader.setAttribute('data-focuscycled', 'active')
		toggleBtnElement.focus()
		siteHeader.ontransitionend = () => {
			toggleBtnElement.focus()
		}
	}
}

const disableCyclicTabbing = (window: BrowserWindow) => {
	if (!process.env.browser) {
		return
	}
	const siteHeader = window!.document.getElementById('SITE_HEADER')
	if (siteHeader) {
		siteHeader.setAttribute('data-focuscycled', 'inactive')
		siteHeader.ontransitionend = null
	}
}

const menuContainerFocusApi = (window: BrowserWindow): IMenuContainerFocusApi => {
	return {
		isMenuContainerToggleOnPage: () => {
			return !!getMenuContainerToggleElement(window)
		},
		enableCyclingInMenuContainer: () => {
			enableCyclicTabbing(window)
		},
		disableCyclingInMenuContainer: () => {
			disableCyclicTabbing(window)
		},
	}
}

export const MenuContainerFocusApi = withDependencies([BrowserWindowSymbol], menuContainerFocusApi)
