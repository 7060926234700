import { withDependencies } from '@wix/thunderbolt-ioc'
import type { MenuContainerComponent, MenuContainerSdkFactory } from './types'
import { ComponentsStoreSymbol } from 'feature-components'

const menuContainerSdkHandlers: MenuContainerSdkFactory = (componentsStore) => {
	return {
		getSdkHandlers: () => ({
			openMenuContainer: (menuContainerId) => {
				componentsStore.get<MenuContainerComponent>(menuContainerId).open()
			},
			closeMenuContainer: (menuContainerId) => {
				componentsStore.get<MenuContainerComponent>(menuContainerId).close()
			},
		}),
	}
}

export const MenuContainerSdkHandlers = withDependencies([ComponentsStoreSymbol], menuContainerSdkHandlers)
