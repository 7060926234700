import { named, withDependencies } from '@wix/thunderbolt-ioc'
import type { MenuContainerWillMountFactory } from './types'
import { name } from './symbols'
import { PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'

const hasMatchingAncestor = (
	element: HTMLElement | null,
	predicates: Array<(el: HTMLElement | null) => boolean>
): boolean => {
	if (!element) {
		return false
	}
	return (
		predicates.some((predicate) => predicate(element)) ||
		hasMatchingAncestor(element?.parentNode as HTMLElement, predicates)
	) // TODO: after the infra will load the closest api, use it instead.
}

const isClickInsideRelevantElement = (target: HTMLElement, id: string) => {
	const anchorPredicate = (element: HTMLElement | null) =>
		!!element && !!element.tagName && element.tagName.toLowerCase() === 'a'
	const overlayPredicate = (element: HTMLElement | null) => !!element && element.id === `overlay-${id}`

	return hasMatchingAncestor(target, [anchorPredicate, overlayPredicate])
}

const menuContainerWillMount: MenuContainerWillMountFactory = ({ componentsConfig }) => {
	return {
		componentTypes: ['MenuContainer'],
		componentWillMount: (menuContainerComp) => {
			if (!componentsConfig[menuContainerComp.id]) {
				return
			}
			menuContainerComp.onClick((e) => {
				const target = e.target as HTMLElement
				if (isClickInsideRelevantElement(target, menuContainerComp.id)) {
					menuContainerComp.toggle(true)
				}
			})

			return () => menuContainerComp.unblockScroll()
		},
	}
}

export const MenuContainerWillMount = withDependencies([named(PageFeatureConfigSymbol, name)], menuContainerWillMount)
